import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  .letter {
    fill-rule: nonzero;
    fill: ${props => (props.letterColor ? props.letterColor : '#4eb548')};
  }
`;

const Logo = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 119 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsSlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    letterColor={fill}
  >
    <rect
      id="Logo-en-naam-volledig"
      x="0"
      y="0"
      width="118.8"
      height="16.8"
      style={{ fill: 'none' }}
    />
    <clipPath id="_clip1">
      <rect x="0" y="0" width="118.8" height="16.8" />
    </clipPath>
    <g clipPath="url(#_clip1)">
      <path
        className="letter"
        d="M18.461,0.116l6.75,0c1.61,0 2.875,0.415 3.795,1.245c0.92,0.83 1.38,1.812 1.38,2.946c0,1.452 -0.711,2.566 -2.134,3.34c1.073,0.304 1.894,0.84 2.464,1.607c0.57,0.767 0.855,1.663 0.855,2.686c0,1.455 -0.466,2.638 -1.397,3.549c-0.931,0.911 -2.302,1.367 -4.113,1.367l-7.6,0l0,-16.74Zm3.889,3.068l0,3.582l2.034,0c0.626,0 1.114,-0.171 1.465,-0.515c0.35,-0.343 0.525,-0.775 0.525,-1.294c0,-0.511 -0.175,-0.935 -0.525,-1.27c-0.351,-0.336 -0.839,-0.503 -1.465,-0.503l-2.034,0Zm0,6.65l0,3.954l2.191,0c1.036,0 1.781,-0.153 2.235,-0.461c0.455,-0.308 0.682,-0.841 0.682,-1.6c0,-0.583 -0.216,-1.044 -0.648,-1.384c-0.432,-0.339 -1.01,-0.509 -1.733,-0.509l-2.727,0Z"
      />
      <path
        className="letter"
        d="M45.564,0.116l3.89,0l0,9.502c0,2.445 -0.675,4.298 -2.023,5.56c-1.349,1.263 -3.275,1.894 -5.779,1.894c-2.473,0 -4.394,-0.639 -5.761,-1.918c-1.367,-1.278 -2.051,-3.115 -2.051,-5.512l0,-9.526l3.901,0l0,9.622c0,1.166 0.355,2.101 1.067,2.804c0.712,0.703 1.645,1.055 2.8,1.055c1.199,0 2.159,-0.36 2.878,-1.079c0.719,-0.719 1.078,-1.741 1.078,-3.067l0,-9.335Z"
      />
      <path
        className="letter"
        d="M65.939,0.116l-5.086,7.873l6.897,8.867l-4.862,0l-6.192,-7.849l0,7.849l-3.889,0l0,-16.74l3.889,0l0,7.537l4.965,-7.537l4.278,0Z"
      />
      <path
        className="letter"
        d="M78.429,0.116l6.935,16.74l-4.182,0l-1.286,-3.307l-6.993,0l-1.166,3.307l-4.121,0l6.307,-16.74l4.506,0Zm0.329,10.509l-2.531,-6.507l-2.293,6.507l4.824,0Z"
      />
      <path
        className="letter"
        d="M100.665,0.116l-8.514,13.672l8.514,0l0,3.068l-15.155,0l8.581,-13.672l-7.989,0l0,-3.068l14.563,0Z"
      />
      <path
        className="letter"
        d="M114.726,0.116l3.889,0l0,9.502c0,2.445 -0.674,4.298 -2.023,5.56c-1.349,1.263 -3.275,1.894 -5.778,1.894c-2.474,0 -4.395,-0.639 -5.762,-1.918c-1.367,-1.278 -2.051,-3.115 -2.051,-5.512l0,-9.526l3.901,0l0,9.622c0,1.166 0.356,2.101 1.067,2.804c0.712,0.703 1.645,1.055 2.8,1.055c1.2,0 2.159,-0.36 2.878,-1.079c0.719,-0.719 1.079,-1.741 1.079,-3.067l0,-9.335Z"
      />
      <g>
        <rect
          x="9.333"
          y="9.333"
          width="7.467"
          height="7.467"
          style={{ fill: '#366732' }}
        />
        <rect
          x="0"
          y="9.333"
          width="7.467"
          height="7.467"
          style={{ fill: '#cb2026' }}
        />
        <rect
          x="9.333"
          y="0"
          width="7.467"
          height="7.467"
          style={{ fill: '#cb2026' }}
        />
        <path
          d="M0,7.467l7.467,0l0,-7.467l-7.467,7.467Z"
          style={{ fill: '#cb2026' }}
        />
      </g>
    </g>
  </Svg>
);

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default Logo;
