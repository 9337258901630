import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg`
  margin-left: 8px;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
`;

const ChevronDown = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 945 602"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    xmlnsSerif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,1640.9,-1150.87)">
      <path
        d="M-1405.28,1517.09L-1640.9,1281.46L-1575.58,1216.16L-1510.26,1150.87L-1339.64,1321.48L-1169.03,1492.09L-998.715,1321.78C-905.042,1228.11 -827.837,1151.47 -827.148,1151.47C-826.459,1151.47 -796.649,1180.72 -760.903,1216.47L-695.91,1281.47L-931.531,1517.1C-1061.12,1646.69 -1167.72,1752.72 -1168.4,1752.72C-1169.09,1752.72 -1275.69,1646.69 -1405.28,1517.09Z"
        style={{ fillRule: 'nonzero' }}
      />
    </g>
  </Svg>
);

ChevronDown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default ChevronDown;
