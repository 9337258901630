import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg`
  enable-background: new 0 0 512.001 512.001;
  margin-right: 8px;
`;

const DeIcon = ({ width, height }) => (
  <Svg
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
  >
    <path
      style={{ fill: '#464655' }}
      d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828
      L512,200.093L512,200.093z"
    />
    <path
      style={{ fill: '#FFE15A' }}
      d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988
      C512,419.773,508.047,423.725,503.172,423.725z"
    />
    <rect y="200.091" style={{ fill: '#FF4B55' }} width="512" height="111.81" />

    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </Svg>
);

DeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default DeIcon;
