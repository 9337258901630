import styled from 'styled-components';
import { pageWidth } from 'constants/theme';

export const Container = styled.div`
  /* padding: 2rem 4rem; */
  max-width: ${pageWidth};
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  &.full-width {
    max-width: 100%;
    padding: 0;
  }
`;
